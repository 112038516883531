import styled from 'styled-components'
import { Link } from 'react-router-dom'
import React from 'react'
import Color from '../../utils/color'
import { deleteUser } from '../../utils/localStorage'

const HeaderNav = styled.header`
  display: flex;
  padding: 1rem;
  a {
    text-decoration: underline;
    margin-right: 1rem;
  }
  border-bottom: 1px solid ${Color.Cyan};
`

const AdminLogo = styled(Link)`
  text-decoration: none !important;
  font-weight: bolder;
`

function logout() {
  deleteUser()
  window.location = '/login'
}

const LogoutLink = styled(Link)`
  right: 20px;
  position: absolute;
`

export default function AdminHeader() {
  return (
    <HeaderNav>
      <AdminLogo to="/admin/stores">pickpack Admin</AdminLogo>
      <Link to="/admin/stores">Stores</Link>
      <Link to="/admin/users">Users</Link>
      <Link to="/admin/campaigns">Campaigns</Link>
      <LogoutLink onClick={logout}>Logout</LogoutLink>
    </HeaderNav>
  )
}
