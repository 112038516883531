import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import queryString from 'query-string'
import Button from '../../components/Button'
import { AuthLogin } from '../../utils/auth'
import { reportError } from '../../utils/error'
import AdminHeader from '../../components/AdminHeader'
import { SessionContext } from '../../providers/SessionContextProvider'
import { deleteUser } from '../../utils/localStorage'

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`

function logout() {
  deleteUser()
  window.location.reload()
}

function Login({ location }) {
  const { user } = useContext(SessionContext)

  function login() {
    const urlParams = queryString.parse(location.search)
    try {
      const { redirectTo = '' } = (location.state || { redirectTo: urlParams.redirect || '/admin/stores' })
      AuthLogin({ redirectTo })
    } catch (error) {
      console.log('Login error', error)
      reportError(error)
    }
  }

  const isAuth0AdminUser = user && !user.id.startsWith('anon|') && user.role === 'ADMINISTRATOR'
  return (
    <>
      {isAuth0AdminUser && <AdminHeader />}
      <PageContainer>
        {isAuth0AdminUser && (
          <p>
            {`Hello, ${user.name}!`}
          </p>
        )}
        <br />
        {!isAuth0AdminUser
          && (
            <>
              <Button onClick={login}>Login</Button>
              <br />
            </>
          )}
        {isAuth0AdminUser && <Button onClick={logout}>Logout</Button>}
      </PageContainer>
    </>
  )
}

Login.propTypes = {
  location: PropTypes.object.isRequired,
}

export default Login
